/** 
 *   分类
 */
import { get } from '@/untils/js/axios.js'

// 获取一级分类
export const getTypeList = params => get('/type/list-one', params)

// 获取二级分类
export const getTwoTypeList = id => get(`/type/list-two/${id}`)

// 获取二级分类下的商品
export const getTypeGoodsList = params => get('/type/goods-list', params)

// 获取筛选列表
export const getFilterList = params => get('/goods/type-off-list', params)
