<template>
  <section class="m-favorite m-scroll">

    <!-- 头部导航 -->
    <m-header title="Wishlist" >
      <div slot="right">
        <!-- <img src="@/assets/icon_Filters@2x.png" alt="" class="top-img" @click="showFilters = true"> -->
        <div class="top-img"></div>
        <span class="top-right" @click="handleEdit">
          {{!showAll ? 'Edit' : 'Done'}}
        </span>
      </div>
    </m-header>

    <!-- 收藏列表 -->
    <van-list  v-model="loading" :immediate-check="false"	:finished="finished" loading-text="loading" finished-text="No More Product" @load="onLoad" class="list">
      <van-swipe-cell v-for="(it,index) in goodsList" :key="index" :disabled="disabled">
        <van-checkbox v-model="it.checked" ref="item" checked-color="#ED2A24" class="item-check">
          <div class="item-wrap" >
            <div class="avatar" @click.stop.prevent="$router.push({path: `/en/goods/detail/${it.id}`, query: {pre: it.pre_info.is_pre == 1 ? 1 : 0}})">
              <img :src="it.image_url" alt="img">
              <div class="eleven" v-if="it.merchant_id == 1 && it.pre_info.is_pre == 0 && elevenShow"></div>
            </div>
            <div class="info">
              <p class="van-multi-ellipsis--l3" @click.stop.prevent="$router.push({path: `/en/goods/detail/${it.id}`, query: {pre: it.pre_info.is_pre == 1 ? 1 : 0}})">{{it.goods_info.name}}</p>
              <div>
                <div>
                  <p v-if="it.pre_info && it.pre_info.pre_min_price != null">
                    <span>$</span><span>{{(it.pre_info.pre_min_price * (it.off == 0 ? 1 : it.off / 100)).toFixed(2)}}</span>
                    <span v-if="it.off != 0">${{it.pre_info.pre_min_price}}</span>
                  </p>
                  <p v-else>
                    <span>$</span><span>{{(it.activity_min_price *1).toFixed(0) != 0 ? it.activity_min_price : (it.min_price *(it.off == 0 ? 1 : it.off/100)).toFixed(2)}}</span>
                    <span v-if="(it.activity_min_price *1).toFixed(0) != 0 ? true : it.off!= 0">${{it.min_price}}</span>
                  </p>
                </div>
                <img src="@/assets/index/iocn-gouwu@2x(1).png" alt="" @click.prevent="handleCart(it.id, $event)">
              </div>
            </div>
          </div>
        </van-checkbox>
        <template #right>
          <img src="@/assets/cart/icon_delete@2x.png" alt="" class="right-img" @click="delFavoriteHandle(it.id, index)">
        </template>
      </van-swipe-cell>
    </van-list>
  
    <!-- 底部全选 取消关注 -->
    <transition name="fade">
      <div v-show="showAll" class="fixed-bar m-padding">
          <van-checkbox v-model="checkedAll" @click="handleCheckAll" checked-color="#ED2A24">All</van-checkbox>
          <span @click="delMoreFavorite">Delete</span>
      </div>
    </transition>

    <van-popup v-model="showFilters" position="bottom" class="fliters-box">
        <IndexHeader title="Fliters" @close="showFilters = false" :isBack="false">
            <span slot="right" class="top-right" @click="resetFilters">Clear</span>
        </IndexHeader>
        <h3>Fliters By Price</h3>
        <div class="price-box">
            <van-slider v-model="priceValue" range  @change="onChange" min="0" max="1000" />
            <div class="price-area">
                <span>${{priceValue[0].toFixed(2)}}</span>
                <span>${{priceValue[1].toFixed(2)}}</span>
            </div>
        </div>
        <h3>Sort</h3>
        <div class="sort-box">
            <div class="box-item" v-for="(item, index) in sortList" :key="index" :class="{'activeSort' : sortActive == index}" @click="sortActive = index">
                {{item.name}}
            </div>
        </div>
        <!-- <h3>Show</h3>
        <div class="sort-box">
            <div class="box-item" v-for="(item, index) in showList" :key="index" :class="{'activeSort' : showActive == index}" @click="showActive = index">
                {{item.name}}
            </div>
        </div> -->
        <h3>Promotions</h3>
        <div class="promo-box">
            <div class="box-item" v-for="(item, index) in promoList" :key="index" :class="{'activePromo' : promoActive == index}"
            @click="promoActive = promoActive == index ? -1 : index">
                {{item.title}}
            </div>
        </div>
        <div class="apply-set" @click="reGetGoods">APPLY</div>
    </van-popup>

    
    <!-- 选择SKU 加入购物车 -->
    <m-sku title="Add to Cart" :show="skuModal" @close="val=>skuModal=val" @success="handleCartSuccess" :goodsId="goodsId"></m-sku>

    <!-- 加入购物车动画 -->
    <m-animate :start="start" :dom="start_dom" @end="handleCartEnd"></m-animate>
  </section>
</template>

<script>
import MHeader  from '@/components/en/tab-header.vue'
import IndexHeader from '@/components/en/m-header.vue'
import MSku         from '@/components/en/m-sku.vue'
import MAnimate     from '@/components/en/cart-animate.vue'

import { getFilterList } from '@/api/en/classify.js'
import { favoriteGoods, getFavoriteGoodsList } from '@/api/en/favorite.js'
export default {
  name:'Index',
  components:{ MHeader, IndexHeader, MAnimate, MSku },
  data(){
    return {
      loading    : false,
      finished   : false,
      showAll    : false,     // 是否显示全选
      disabled   : false,    // 是否禁止滑动
      showFilters: false, // 打开筛选
      priceValue : [0, 1000], // 价格筛选
      sortList   : [
        {name    : 'Comprehensive'},
        {name    : 'Added time'},
        {name    : 'User rating'},
        {name    : 'Price: Positive order'},
        {name    : 'Price: Reverse order'}
      ],
      showList   : [
        {name    : "Unpurchased"},
        {name    : 'Purchased'},
        {name    : 'All items'}
      ],
      promoList  : [],
      sortActive : 0,
      promoActive: -1,
      showActive : 0,
      goodsList  : [],
      page       : 1,
      islock     : false,
      noMore     : false,
      start      : false,
      skuModal   : false,
      start_dom  : null,
      goodsId    : '',
      elevenShow: false // 圣诞活动边框显示
    }
  },

  computed: {
    checkedAll: {
      set(){
        return false
      },
      get(){
        let bol = false
        this.goodsList.forEach(e => {
          if(!e.checked){
            bol = true
          }
        })
        if(bol){
          return false
        }else{
          return true
        }
      }
    },
  },

  methods:{
    // 加载列表
    onLoad(){
      if(!this.islock && !this.noMore){
        this.page++
        this.getFavoriteGoodsListHandle()
      }
    },
    // 点击头部编辑
    handleEdit(){
      if(!this.showAll) {
        this.showAll  = true
        this.disabled = true
        // console.log(this.$refs.item[0])
        if(this.$refs.item != undefined) {
          this.$refs.item.forEach(e => {
            e.$el.style.left = '0px'
          })
        }
      } else {
        this.HandleAll()
      }
    },

    // 点击头部完成
    HandleAll(){
      this.showAll  = false 
      this.disabled = false
      if(this.$refs.item != undefined) {
        this.$refs.item.forEach(e => {
          e.$el.style.left = '-36px'
        })
      }
    },
    // 点击全选
    handleCheckAll(){
      if(this.checkedAll){
        this.goodsList.forEach(e => {
          e.checked = false
        })
      }else{
        this.goodsList.forEach(e => {
          e.checked = true
        })
      }
    },
    resetFilters() {
        // 重置筛选条件
        this.priceValue  = [0, 1000]
        this.sortActive  = 0
        this.promoActive = -1
        this.showActive  = 0
    },
    onChange(value) {
      this.$toast('当前值：' + value);
    },
    // 商品列表购物车点击事件
    handleCart(goods,e){
      this.goodsId = goods
      this.skuModal  = true
      this.start_dom = e
    },
    // sku 弹窗加入购物车成功回调
    handleCartSuccess(){
      this.skuModal = false
      let timer = setTimeout( () => {
          this.start = true
          clearTimeout(timer)
      }, 300)
    },
    // 加入购物车动画结束
    handleCartEnd(){
        this.start = false
    },
    getFavoriteGoodsListHandle() {
      this.islock  = true
      this.loading = true
      let params = {
        status: this.sortActive - 0 + 1,
        page: this.page,
        min_price: this.priceValue[0] == 0   ? null : this.priceValue[0],
        max_price: this.priceValue[1] == 999 ? null : this.priceValue[1],
        off: this.promoActive == -1 ? null : this.promoList[this.promoActive].off
      }
      getFavoriteGoodsList(params).then(res => {
        if(res) {
          res.data.data.forEach(el => {
            el.checked = false
          })
          this.goodsList = this.goodsList.concat(res.data.data)
          if(res.data.data.length < res.data.per_page) {
            this.finished = true
          }
        } else {
          this.finished = true
        }
      }).finally(() => {
        this.loading = false
        this.islock  = false
      })
    },
    reGetGoods() {
      // 重新获取数据
      this.page        = 1
      this.noMore      = false
      this.goodsList   = []
      this.finished    = false
      this.showFilters = false
      this.getFavoriteGoodsListHandle()
    },
    delMoreFavorite() {
      // 取消多个收藏
      let arr = [], index = []
      this.goodsList.forEach((el, ind) => {
        if(el.checked) {
          arr.push(el.id)
          index.push(ind)
        }
      })
      this.HandleAll()
      this.delFavoriteHandle(arr.join(','), index)
    },
    delFavoriteHandle(id, index) {
      favoriteGoods(id,{status: 2}).then(res => {
        if(res) {
          if(typeof(index) == 'object') {
            index.forEach((el, ind) => {
              this.goodsList.splice(el - ind, 1)
            })
          } else {
            this.goodsList.splice(index, 1)
          }
          this.$notify({ type: 'success', message: res.msg })
        } else {
          this.$notify({ type: 'warning', message: res.msg })
        }
      })
    },
    getFilterListHandle() {
      getFilterList().then(res => {
        if(res) {
          this.promoList = res.data
        }
      })
    },
  },
  created() {
    this.getFavoriteGoodsListHandle()
    this.getFilterListHandle()
  }
}
</script>

<style lang="less" scoped>
.top-img{width:22px;height:22px;margin-right:15px;vertical-align: -5px;display: inline-block;}
.top-right{font-size: 12px;font-weight:600;color: #2A65BA !important;}
.right-img{width:44px;height:44px;margin:45px 15px 0 0}
.fliters-box{width: 100%;height: 560px;}
@import './favorite.less';
</style>